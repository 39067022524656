/* eslint-disable camelcase */
import en_shared from './shared/copy/en'
import en_account from './account/copy/en'
// import en_compose from './modules/compose/copy/en'

export default {
  en: {
    shared: en_shared,
    account: en_account
    // claims: en_claims,
  }
}
